<template>
  <div id="school-classes" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="schoolClasses" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          schoolClasses.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Number Of Students" v-slot="props">{{
          props.row.female + props.row.male
        }}</b-table-column>

        <b-table-column label="Promotion Class" v-slot="props">{{
          props.row.promotionClass ? props.row.promotionClass.name : 'None'
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="school-classes"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'school-classes',
  data() {
    return {
      schoolId: null,
      pageTitle: 'School Classes',
      schoolClass: {},
      schoolClasses: [],
      id: null,
    }
  },
  methods: {
    initializeInfo(school_class) {
      this.schoolClass = school_class
      this.$store.commit('setRecord', school_class)
      this.$router.push(
        `/school/${this.schoolId}/school_class_info/${school_class.id}`
      )
    },
    initializeUpdate(school_class) {
      this.schoolClass = school_class
      this.$router.push(
        `/school/${this.schoolId}/school_class/${school_class.id}`
      )
    },
    initializeRemove(school_class) {
      this.id = parseInt(school_class.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteSchoolClassQuery($id: Int!) {
              deleteSchoolClass(input: { id: $id }) {
                schoolClass {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteSchoolClass.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.schoolClasses.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/school_class/new`,
      },
      {
        name: 'School Class Hierarchy',
        route: `/school/${this.schoolId}/school_class_hierarchy`,
      },
    ])

    this.$apollo.addSmartQuery('schoolClasses', {
      query: SCHOOL_CLASSES,
      variables: {
        schoolId: this.schoolId,
      },
    })
    this.$apollo.queries.schoolClasses.refetch()
  },
}
</script>
